import {
  Button,
  Group,
  Text,
  Textarea,
  Loader,
  ScrollArea,
  ActionIcon,
  Grid,
  createStyles,
  Container,
  Stack,
  SimpleGrid
} from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { IconArrowAutofitDown, IconSearch, IconMessageCircle, IconUser } from '@tabler/icons';
import logo from '../../favicon32.png';
import { v4 as uuidv4 } from 'uuid';
import { FileWithPath } from '@mantine/dropzone';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { getToken, getUser } from '../AuthComponents/auth';
import instance from '../../axiosApi';
import Break from '../Break';
import { useRef } from 'react';
import SourcesAccordion from './SourcesAccordion';
import UploadDropzone from '../PdfPreviews/UploadDropZone';

interface Prompts {
  prompt: string;
  type: string;
}

interface ResponsesSearch {
  text: string;
  similarity: number;
  id: string;
  pageNumber: number;
  start: number;
  end: number;
}

interface ResponseDocChat {
  text: string;
  id: string;
  sources: ResponsesSearch[];
}

interface CombinedProps {
  uploadedFiles: FileWithPath[];
  setUploadedFiles: (uploadedFiles: FileWithPath[]) => void;
  documentText: string;
  setDocumentText: (documentText: string) => void;
  isLargeDoc: boolean;
  setIsLargeDoc: (isLargeDoc: boolean) => void;
  isTooLarge: boolean;
  setIsTooLarge: (isTooLarge: boolean) => void;
  chatCredits: number;
  setChatCredits: (chatCredits: number) => void;
  searchCredits: number;
  setSearchCredits: (searchCredits: number) => void;
  textLength: number;
  setTextLength: (textLength: number) => void;
  sentenceCount: number;
  setSentenceCount: (sentenceCount: number) => void;
  wordCount: number;
  setWordCount: (wordCount: number) => void;
  searchResults: any[];
  setSearchResults: (searchResults: any[]) => void;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  setActiveTab: (activeTab: string | null) => void;
}

function Combined(props: CombinedProps): JSX.Element {
  const {
    uploadedFiles,
    setUploadedFiles,
    documentText,
    setDocumentText,
    isTooLarge,
    setIsTooLarge,
    isLargeDoc,
    setIsLargeDoc,
    chatCredits,
    setChatCredits,
    searchCredits,
    setSearchCredits,
    textLength,
    setTextLength,
    sentenceCount,
    setSentenceCount,
    wordCount,
    setWordCount,
    searchResults,
    setSearchResults,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    setActiveTab
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('sentence');
  const [error, setError] = useState('');
  const [promptBefore, setPromptBefore] = useState('');
  const [responseListDocChat, setResponseListDocChat] = useState<ResponseDocChat[]>([
    {
      text: 'Welcome to AnalyseDocs.com! Please upload a document to get started.',
      id: uuidv4(),
      sources: []
    }
  ]);
  const [promptList, setPromptList] = useState<Prompts[]>([{ prompt: '', type: 'Chat' }]); // This is the list of strings that will be displayed in the prompt text area.
  const viewport = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const heightVal = isMobile ? 500 : 500;
  const useStyles = createStyles((theme) => ({
    botContainer: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      borderRadius: theme.radius.md,
      padding: theme.spacing.sm,
      marginBottom: theme.spacing.sm
    },
    chatBox: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      padding: theme.spacing.xs,
      borderRadius: theme.radius.md,
      borderShadow: theme.shadows.sm,
      border: '1px solid',
      // Height is dynamically set depending on the screen size:
      height: heightVal
    }
  }));
  const { classes } = useStyles();

  // Code to check if a file has been uploaded and then append a new response to the responseTextListChat array.
  // The new string will be 'Thank you for uploading ' + the name of the file.
  useEffect(() => {
    if (uploadedFiles.length > 0 && promptList.length === 1 && wordCount > 0) {
      setPromptList((prev) => [...prev, { prompt: '', type: 'Chat' }]);
      setResponseListDocChat((prev) => [
        ...prev,
        {
          text:
            'Thank you for uploading ' +
            uploadedFiles[0].name +
            '.' +
            ' This document has ' +
            sentenceCount +
            ' sentences, ' +
            wordCount +
            ' words, and ' +
            textLength +
            ' characters.' +
            ' You can now begin querying the document. ' +
            'If you are unsure of how to use the Doc Chat and Search options, please refer to the FAQs.',
          id: uuidv4(),
          sources: []
        }
      ]);
    }
  }, [wordCount]);

  function scrollToBottom() {
    // Scroll to bottom of chat box
    viewport.current?.scrollTo({ top: viewport.current.scrollHeight + 10, behavior: 'smooth' });
  }

  useEffect(() => {
    scrollToBottom();
  }, [responseListDocChat]);

  const handleSearchRequest = async () => {
    setIsLoading(true);
    setError('');

    const formData = new FormData();
    const token = await getToken();
    const user = await getUser();

    if (uploadedFiles.length > 0) {
      formData.append('file', uploadedFiles[0]);
    } else {
      setError('Please upload a file.');
      setIsLoading(false);
      return;
    }

    if (!token) {
      setError('Please login or register.');
      setIsLoading(false);
      return;
    }
    if (!user) {
      setError('Please login or register.');
      setIsLoading(false);
      return;
    }
    if (user.searchCredits < 1) {
      setError('You do not have enough credits. Please purchase more credits.');
      setIsLoading(false);
      return;
    }
    if (user.searchCredits < searchCredits) {
      setError('You do not have enough credits. Please purchase more credits.');
      setIsLoading(false);
      return;
    }

    if (!user.isEmailVerified) {
      setError('Please verify your email.');
      setIsLoading(false);
      return;
    }
    // if (isTooLarge) {
    //   setError('Your file is too large. Please upload a smaller file.');
    //   setIsLoading(false);
    //   return;
    // }
    if (promptBefore.length === 0) {
      setError('Please enter a prompt.');
      setIsLoading(false);
      return;
    }
    if (promptBefore.length > 1250) {
      setError('Please enter a prompt with fewer than 8000 chars / 1500 words');
      setIsLoading(false);
      return;
    }
    formData.append('email', user.email);
    formData.append('userID', user.userID);
    formData.append('isEmailVerified', user.isEmailVerified);
    formData.append('splitBy', value);
    formData.append('query', promptBefore);
    scrollToBottom();
    try {
      const response = await instance.post('/api/search/semanticSearch', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token
        }
      });
      setPromptList((prev: any) => [
        ...prev,
        {
          prompt: promptBefore,
          type: 'Search'
        }
      ]);

      console.log(response.data);
      const sources = response.data.textList;
      setSearchResults(sources);

      const newData: ResponseDocChat = {
        text: '',
        sources: sources,
        id: uuidv4()
      };

      setResponseListDocChat((prev: any) => [...prev, newData]);
    } catch (error: any) {
      // Error handling to catch and display common error codes:
      // 400: Bad Request
      // 401: Unauthorized
      // 403: Forbidden
      // 404: Not Found
      // 500: Internal Server Error
      // 502: Bad Gateway
      // 503: Service Unavailable
      // 504: Gateway Timeout
      if (error.response) {
        switch (error.response.status) {
          case 400:
            setError(error.response.data.message);
            break;
          case 401:
            setError('Unauthorized - Please login or register.');
            break;
          case 403:
            setError(error.response.data.message);
            break;
          case 404:
            setError('Not Found');
            break;
          case 413:
            setError(error.response.data.message);
            break;
          case 500:
            setError('Your request could not be processed. Please try again or contact us.');
            break;
          case 502:
            setError(error.response.data.message);
            break;
          case 503:
            setError('Service Unavailable');
            break;
          case 504:
            setError('Gateway Timeout');
            break;
          default:
            setError('An error occurred while analysing the file.');
        }
      }
    }
    setIsLoading(false);
    scrollToBottom();
  };

  const handleDocChatRequest = async () => {
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    const token = await getToken();
    const user = await getUser();
    if (!token) {
      setError('Please login or register.');
      setIsLoading(false);
      return;
    }
    if (!user) {
      setError('Please login or register.');
      setIsLoading(false);
      return;
    }
    if (user.credits < 1) {
      setError('You do not have enough credits. Please purchase more credits.');
      setIsLoading(false);
      return;
    }
    if (user.credits < chatCredits) {
      setError('You do not have enough credits. Please purchase more credits.');
      setIsLoading(false);
      return;
    }
    if (!user.isEmailVerified) {
      setError('Please verify your email.');
      setIsLoading(false);
      return;
    }
    if (isTooLarge) {
      setError('Your file is too large. Please upload a smaller file.');
      setIsLoading(false);
      return;
    }
    if (promptBefore.length === 0) {
      setError('Please enter a prompt.');
      setIsLoading(false);
      return;
    }
    // if (promptBefore.length > 8000) {
    //   setError('Please enter a prompt with fewer than 8000 chars / 1500 words');
    //   setIsLoading(false);
    //   return;
    // }
    formData.append('file', uploadedFiles[0]);
    formData.append('promptBefore', promptBefore);
    formData.append('email', user.email);
    formData.append('userID', user.userID);
    formData.append('isEmailVerified', user.isEmailVerified);
    // formData.append('query', promptBefore);

    const query = {
      prompt: promptBefore,
      promptList: promptList,
      responseList: responseListDocChat
    };
    console.log(query);
    formData.append('query', JSON.stringify(query));
    scrollToBottom();
    try {
      // const response = await instance.post('/api/search/docChat', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     Authorization: token
      //   }
      // });
      const response = await instance.post('/api/search/docChatGPT', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token
        }
      });

      // else {
      //   response = await instance.post('/api/analysePdf', formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //       Authorization: token
      //     }
      //   });
      //}

      setPromptList((prev: any) => [
        ...prev,
        {
          prompt: promptBefore,
          type: 'Chat'
        }
      ]);
      const chatResponse = response.data.text;
      const sources = response.data.textList;
      setSearchResults(sources);
      // Set the responses for both the search and chat

      const newData: ResponseDocChat = {
        text: chatResponse,
        sources: sources,
        id: uuidv4()
      };

      setResponseListDocChat((prev: any) => [...prev, newData]);
    } catch (error: any) {
      // Error handling to catch and display common error codes:
      // 400: Bad Request
      // 401: Unauthorized
      // 403: Forbidden
      // 404: Not Found
      // 500: Internal Server Error
      // 502: Bad Gateway
      // 503: Service Unavailable
      // 504: Gateway Timeout
      if (error.response) {
        switch (error.response.status) {
          case 400:
            setError(error.response.data.message);
            break;
          case 401:
            setError('Unauthorized - Please login or register.');
            break;
          case 403:
            setError(error.response.data.message);
            break;
          case 404:
            setError('Not Found');
            break;
          case 413:
            setError(error.response.data.message);
            break;
          case 500:
            setError('Your request could not be processed. Please try again or contact us.');
            break;
          case 502:
            setError(error.response.data.message);
            break;
          case 503:
            setError('Service Unavailable');
            break;
          case 504:
            setError('Gateway Timeout');
            break;
          default:
            setError('An error occurred while analysing the file.');
        }
      }
    }
    setIsLoading(false);
    // Scroll to bottom of viewport
    scrollToBottom();
  };

  return (
    <div>
      <>
        <Break />
        <Container className={classes.chatBox}>
          <Group position="apart">
            {uploadedFiles.length > 0 ? (
              <>
                <Text
                  size="xs"
                  color="dimmed"
                  style={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}>
                  {uploadedFiles[0].name}
                </Text>
              </>
            ) : (
              <div />
            )}
            <ActionIcon onClick={() => scrollToBottom()}>
              <IconArrowAutofitDown size={20} stroke={1.5} />
            </ActionIcon>
          </Group>
          <Break height={0.01} isLine />
          <ScrollArea
            style={{ height: '95%', width: '100%' }}
            type="never"
            scrollbarSize={2}
            offsetScrollbars
            viewportProps={{ style: { width: '100%', height: '90%' } }}
            viewportRef={viewport}>
            <>
              {promptList &&
                promptList.map((prompt, index) => (
                  <>
                    {prompt.prompt !== '' && (
                      <Container className={classes.botContainer}>
                        <Grid>
                          <Grid.Col span={1}>
                            <IconUser style={{ color: '#00B8D9' }} size={20} />{' '}
                          </Grid.Col>
                          <Grid.Col span={'auto'}>
                            <Text style={{ textAlign: 'left' }} size="sm">
                              {prompt.prompt}
                            </Text>
                          </Grid.Col>
                        </Grid>
                      </Container>
                    )}

                    {prompt.type === 'Chat' ? (
                      <>
                        <Container className={classes.botContainer}>
                          <Grid>
                            <Grid.Col span={1}>
                              <img
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  objectFit: 'contain',
                                  objectPosition: 'center',
                                  borderRadius: '50%'
                                }}
                                src={logo}
                                alt="Logo"
                              />
                            </Grid.Col>
                            <Grid.Col span={'auto'}>
                              <Text style={{ textAlign: 'left' }} size="sm">
                                {responseListDocChat && responseListDocChat[index].text}
                              </Text>
                            </Grid.Col>
                          </Grid>

                          {responseListDocChat && responseListDocChat[index].sources.length > 0 && (
                            <SourcesAccordion
                              promptType={prompt.type}
                              sources={responseListDocChat[index].sources}
                              setSearchText={setSearchText}
                              setPageNumber={setPageNumber}
                              setActiveTab={setActiveTab}
                            />
                          )}
                        </Container>
                        {promptList.length === 1 && isMobile && (
                          <UploadDropzone
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            documentText={documentText}
                            setDocumentText={setDocumentText}
                            isLargeDoc={isLargeDoc}
                            setIsLargeDoc={setIsLargeDoc}
                            isTooLarge={isTooLarge}
                            setIsTooLarge={setIsTooLarge}
                            chatCredits={chatCredits}
                            setChatCredits={setChatCredits}
                            searchCredits={searchCredits}
                            setSearchCredits={setSearchCredits}
                            textLength={textLength}
                            setTextLength={setTextLength}
                            sentenceCount={sentenceCount}
                            setSentenceCount={setSentenceCount}
                            wordCount={wordCount}
                            setWordCount={setWordCount}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        )}
                      </>
                    ) : prompt.type === 'Search' ? (
                      <>
                        <Container className={classes.botContainer}>
                          {responseListDocChat && responseListDocChat[index].sources.length > 0 && (
                            <>
                              <Grid>
                                <Grid.Col span={1}>
                                  <img
                                    style={{
                                      width: '25px',
                                      height: '25px',
                                      objectFit: 'contain',
                                      objectPosition: 'center',
                                      borderRadius: '50%'
                                    }}
                                    src={logo}
                                    alt="Logo"
                                  />
                                </Grid.Col>
                                <Grid.Col span={'auto'}>
                                  <Text style={{ textAlign: 'left' }} size="sm">
                                    I found{' '}
                                    {responseListDocChat &&
                                      responseListDocChat[index].sources.length}{' '}
                                    matches to your query. Click "Show Sources" to view them.
                                  </Text>
                                </Grid.Col>
                              </Grid>
                              <SourcesAccordion
                                promptType={prompt.type}
                                sources={responseListDocChat[index].sources}
                                setSearchText={setSearchText}
                                setPageNumber={setPageNumber}
                                setActiveTab={setActiveTab}
                              />
                            </>
                          )}
                        </Container>
                      </>
                    ) : null}
                  </>
                ))}
              {isLoading ? (
                <>
                  <Container className={classes.botContainer}>
                    <Grid>
                      <Grid.Col span={1}>
                        <IconUser style={{ color: '#00B8D9' }} size={20} />{' '}
                      </Grid.Col>
                      <Grid.Col span={'auto'}>
                        <Text style={{ textAlign: 'left' }} size="sm">
                          {promptBefore}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  </Container>
                  <Container className={classes.botContainer}>
                    <Grid>
                      <Grid.Col span={1}>
                        <img
                          style={{
                            width: '25px',
                            height: '25px',
                            objectFit: 'contain',
                            objectPosition: 'center',
                            borderRadius: '50%'
                          }}
                          src={logo}
                          alt="Logo"
                        />
                      </Grid.Col>
                      <Grid.Col span={'auto'}>
                        <Text style={{ textAlign: 'left' }} size="sm">
                          <Loader variant="dots" />{' '}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  </Container>
                </>
              ) : (
                <></>
              )}
            </>
          </ScrollArea>
        </Container>
        <Break height={1} />
        {error ? (
          <Text size="sm" color="red">
            {error}
          </Text>
        ) : (
          <></>
        )}

        {isMobile ? (
          <Stack>
            <Textarea
              rightSection={
                // Display counter for number of characters in the input at the bottom of the div
                <div style={{ position: 'absolute', bottom: '0', right: '0', padding: 5 }}>
                  <Text size="sm" color="dimmed">
                    {promptBefore.length}/250
                  </Text>
                </div>
              }
              // error if length goes above 250 characters:
              error={promptBefore.length > 250}
              placeholder="Write your query here. Ctrl+D Doc Chat | Ctrl+S Search."
              radius="sm"
              value={promptBefore}
              onChange={(e) => setPromptBefore(e.target.value)}
              onKeyDown={getHotkeyHandler([
                ['mod+d', handleDocChatRequest],
                ['mod+s', handleSearchRequest]
              ])}
            />
            {uploadedFiles.length > 0 ? (
              <SimpleGrid cols={2}>
                <Button
                  compact
                  loaderPosition="left"
                  variant="gradient"
                  leftIcon={<IconSearch size={20} stroke={1.5} />}
                  loading={isLoading}
                  onClick={handleSearchRequest}>
                  Search
                </Button>
                <Button
                  compact
                  loaderPosition="left"
                  variant="gradient"
                  leftIcon={<IconMessageCircle size={20} stroke={1.5} />}
                  loading={isLoading}
                  onClick={handleDocChatRequest}>
                  Doc Chat
                </Button>
              </SimpleGrid>
            ) : (
              <SimpleGrid cols={2}>
                <Button
                  disabled
                  compact
                  loaderPosition="left"
                  variant="gradient"
                  leftIcon={<IconSearch size={20} stroke={1.5} />}
                  loading={isLoading}
                  onClick={handleSearchRequest}>
                  Search
                </Button>
                <Button
                  disabled
                  compact
                  loaderPosition="left"
                  variant="gradient"
                  leftIcon={<IconMessageCircle size={20} stroke={1.5} />}
                  loading={isLoading}
                  onClick={handleDocChatRequest}>
                  Doc Chat
                </Button>
              </SimpleGrid>
            )}
          </Stack>
        ) : (
          <Grid>
            <Grid.Col span={'auto'}>
              <Textarea
                rightSection={
                  // Display counter for number of characters in the input at the bottom of the div
                  <div style={{ position: 'absolute', bottom: '0', right: '0', padding: 5 }}>
                    <Text size="sm" color="dimmed">
                      {promptBefore.length}/250
                    </Text>
                  </div>
                }
                // error if length goes above 250 characters:
                error={promptBefore.length > 250}
                placeholder="Write your query here. Ctrl+D Doc Chat | Ctrl+S Search."
                radius="sm"
                value={promptBefore}
                onChange={(e) => setPromptBefore(e.target.value)}
                onKeyDown={getHotkeyHandler([
                  ['mod+d', handleDocChatRequest],
                  ['mod+s', handleSearchRequest]
                ])}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              {uploadedFiles.length > 0 ? (
                <Group spacing="xs" position="right">
                  <Button
                    fullWidth
                    compact
                    loaderPosition="left"
                    variant="gradient"
                    leftIcon={<IconSearch size={20} stroke={1.5} />}
                    loading={isLoading}
                    onClick={handleSearchRequest}>
                    Search
                  </Button>
                  <Button
                    fullWidth
                    compact
                    loaderPosition="left"
                    variant="gradient"
                    leftIcon={<IconMessageCircle size={20} stroke={1.5} />}
                    loading={isLoading}
                    onClick={handleDocChatRequest}>
                    Doc Chat
                  </Button>
                </Group>
              ) : (
                <Group spacing="xs" position="right">
                  <Button
                    disabled
                    fullWidth
                    compact
                    loaderPosition="left"
                    variant="gradient"
                    leftIcon={<IconSearch size={20} stroke={1.5} />}
                    loading={isLoading}
                    onClick={handleSearchRequest}>
                    Search
                  </Button>
                  <Button
                    disabled
                    fullWidth
                    compact
                    loaderPosition="left"
                    variant="gradient"
                    leftIcon={<IconMessageCircle size={20} stroke={1.5} />}
                    loading={isLoading}
                    onClick={handleDocChatRequest}>
                    Doc Chat
                  </Button>
                </Group>
              )}
            </Grid.Col>
          </Grid>
        )}
      </>
    </div>
  );
}

export default Combined;
