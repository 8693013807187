import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    isAuthenticating: true
  },
  reducers: {
    setIsAuthenticated: (state, action) => {
      // set the state to the payload value (true or false) for onlhy the isAuthenticated property
      return { ...state, isAuthenticated: action.payload };
    },
    setIsAuthenticating: (state, action) => {
      // set the state to the payload value (true or false) for onlhy the isAuthenticating property
      return { ...state, isAuthenticating: action.payload };
    }
  }
});

export const { setIsAuthenticated, setIsAuthenticating } = authSlice.actions;

export default authSlice.reducer;
