import ColorSchemeToggle from './components/ColorSchemeToggle/ColorSchemeToggle';
import { useState } from 'react';
import { themeDark, themeLight } from './themes';
import {
  AppShell,
  Footer,
  Group,
  LoadingOverlay,
  MantineProvider,
  Header,
  Title,
  createStyles,
  Navbar,
  Text,
  ScrollArea,
  MediaQuery,
  Burger,
  UnstyledButton,
  Button
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import instance from './axiosApi';
import { useEffect } from 'react';
import { getUser, getToken, setUser, resetUserSession } from './components/AuthComponents/auth';
import BurgerMenu from './components/BurgerMenu';
import { setIsAuthenticated, setIsAuthenticating } from './mainStoreSlices/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Dots } from './components/Dots';
import { useViewportSize } from '@mantine/hooks';
import { IconMessageChatbot } from '@tabler/icons';

function App(props: {
  children: React.ReactNode;
  navBarVisible?: boolean;
  footerVisible?: boolean;
  headerVisible?: boolean;
}) {
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const [theme, setTheme] = useState(themeDark);
  const toggleTheme = (value: any) => {
    const nextColorScheme = value || (theme === themeDark ? themeLight : themeDark);
    setTheme(nextColorScheme);
  };

  const [opened, setOpened] = useState(false);

  const { height, width } = useViewportSize();
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    // Verify if user is logged in and has a valid token:
    // If yes, set isAuthenticated to true and set user
    // If no, set isAuthenticated to false and reset userSession
    dispatch(setIsAuthenticating(true));
    const user = getUser();
    const token = getToken();

    console.log('user in App.tsx: ', user);
    console.log('token in App.tsx: ', token);
    if (!token) {
      dispatch(setIsAuthenticating(false));
      return;
    }
    if (!user) {
      dispatch(setIsAuthenticating(false));
      return;
    }

    const verifyToken = async () => {
      try {
        const response = await instance.post('/api/auth/verify', { token, user });
        dispatch(setIsAuthenticating(false));
        dispatch(setIsAuthenticated(true));
        setUser(response.data);
      } catch (error) {
        resetUserSession();
        dispatch(setIsAuthenticating(false));
        dispatch(setIsAuthenticated(false));
      }
    };
    verifyToken();
  }, [dispatch]);

  let shell = <></>;
  const footer = <></>;
  let header = <></>;
  let navbar = <></>;

  if (props.headerVisible) {
    if (authState.isAuthenticated) {
      header = (
        <Header height={60} p="xs">
          <Group position="apart">
            <Title
              onClick={() => {
                window.location.href = '/';
              }}
              variant="gradient"
              style={{
                cursor: 'pointer'
              }}
              order={3}>
              docmaster.ai
            </Title>{' '}
            <div>
              {/* <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  // color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery> */}
              <BurgerMenu />
            </div>
          </Group>
        </Header>
      );
    } else {
      header = (
        <Header height={60} p="xs">
          <Group position="apart">
            <Title
              onClick={() => {
                window.location.href = '/';
              }}
              variant="gradient"
              style={{
                cursor: 'pointer'
              }}
              order={3}>
              docmaster.ai
            </Title>{' '}
            <div>
              {/* // Login and sign up buttons */}

              <Button
                onClick={() => {
                  window.location.href = '/login';
                }}
                variant="outline"
                size="sm"
                mr="sm">
                Login
              </Button>
              <Button
                onClick={() => {
                  window.location.href = '/register';
                }}
                variant="gradient"
                size="sm">
                Register
              </Button>
            </div>
          </Group>
        </Header>
      );
    }
  }
  if (props.navBarVisible) {
    navbar = (
      <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 200 }}>
        <UnstyledButton
          onClick={() => {
            window.location.href = '/my-chat-bots';
          }}
          sx={(theme) => ({
            display: 'block',
            width: '100%',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

            '&:hover': {
              backgroundColor: theme.colors.gray[theme.colorScheme === 'dark' ? 7 : 2]
            }
          })}>
          <Group>
            <IconMessageChatbot size={20} />

            <Text size="sm">My Chat bots</Text>
          </Group>
        </UnstyledButton>
        <UnstyledButton
          onClick={() => {
            window.location.href = '/account';
          }}
          sx={(theme) => ({
            display: 'block',
            width: '100%',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

            '&:hover': {
              backgroundColor: theme.colors.gray[theme.colorScheme === 'dark' ? 7 : 2]
            }
          })}>
          <Group>
            <IconMessageChatbot size={20} />

            <Text size="sm">Account</Text>
          </Group>
        </UnstyledButton>
      </Navbar>
    );
  }

  shell = (
    <AppShell
      styles={{}}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={navbar}
      header={header}>
      <>{props.children}</>
    </AppShell>
  );

  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <LoadingOverlay visible={authState.isAuthenticating} overlayOpacity={1} />
      <Dots style={{ left: 0, top: 0 }} />
      {shell}
    </MantineProvider>
  );
}
export default App;
