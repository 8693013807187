import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import store from './components/ChatBotCreator/store';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AccountPage from './components/AuthComponents/AccountPage';
import reportWebVitals from './reportWebVitals';
import PaymentSuccess from './components/PaymentSuccessPage';
import NotFoundPage from './components/NotFoundPage';
import LandingPage from './components/LandingPage';
import ChatBotCreator from './components/ChatBotCreator/ChatBotCreator';
import MainContentCombined from './components/MainContentCombined';
import mockdata from './components/Articles/articleData';
import ArticleTemplate from './components/Articles/ArticleTemplate';
import CreateChatBotPage from './components/ChatBotCreator/CreateChatBotPage';
import MyChatBots from './components/ChatBotCreator/MyChatBotsPage';
import LoginForm from './components/AuthComponents/LoginForm';
import RegisterForm from './components/AuthComponents/RegisterForm';
import mainStore from './mainStore';
import PrivateRoute from './components/PrivateRoute';
import { Card, Container, Group, Title } from '@mantine/core';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // React StrictMode renders twice in development mode:
  // <StrictMode>
  // Iterate over mockdata and render each article using the ArticleTemplate component:
  <BrowserRouter>
    <Provider store={mainStore}>
      <Routes>
        {mockdata.map((article) => (
          <Route
            key={article.title}
            path={article.path}
            element={
              <App>
                <ArticleTemplate article={article} />
              </App>
            }
          />
        ))}
        {/* <Route
          path="/account"
          element={
            <PrivateRoute>
              <App navBarVisible headerVisible>
                <AccountPage />
              </App>
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/register"
          element={
            <App>
              <Group mt={100} position="center">
                <Card
                  style={{
                    // Background color of the card: light grey
                    backgroundColor: '#25262B',
                    borderRadius: '10px',
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    border: '0.01px solid #1e1e1e',
                    borderColor: 'grey',
                    maxWidth: '400px',
                    width: '75vw'
                  }}
                  shadow="md"
                  padding="lg">
                  <Title
                    onClick={() => {
                      window.location.href = '/';
                    }}
                    mb={10}
                    variant="gradient"
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center'
                    }}
                    order={2}>
                    docmaster.ai
                  </Title>
                  <Title
                    style={{
                      textAlign: 'center'
                    }}
                    mb={10}
                    order={6}>
                    Create an account
                  </Title>
                  <RegisterForm />
                </Card>
              </Group>
            </App>
          }
        />
        <Route
          path="/login"
          element={
            <App>
              <Group mt={100} position="center">
                <Card
                  style={{
                    backgroundColor: '#25262B',
                    borderRadius: '10px',
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    border: '0.01px solid #1e1e1e',
                    borderColor: 'grey',
                    maxWidth: '400px',
                    width: '75vw'
                  }}
                  shadow="md"
                  padding="lg">
                  <Title
                    onClick={() => {
                      window.location.href = '/';
                    }}
                    mb={10}
                    variant="gradient"
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center'
                    }}
                    order={2}>
                    docmaster.ai
                  </Title>
                  <Title
                    style={{
                      textAlign: 'center'
                    }}
                    mb={10}
                    order={6}>
                    Login to your account
                  </Title>
                  <LoginForm />
                </Card>
              </Group>
            </App>
          }
        />
        <Route
          path="/pdf-viewer"
          element={
            <App navBarVisible headerVisible>
              <MainContentCombined />
            </App>
          }
        />{' '}
        {/* <Route
          path="payment-success"
          element={
            <PrivateRoute>
              <App>
                <PaymentSuccess />
              </App>
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/"
          element={
            <App headerVisible>
              <LandingPage />
            </App>
          }
        />
        {/* <Route
          path="/create-a-bot"
          element={
            <PrivateRoute>
              <App navBarVisible headerVisible>
                <CreateChatBotPage />
              </App>
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="/my-chat-bots"
          element={
            <PrivateRoute>
              <App navBarVisible headerVisible>
                <MyChatBots />
              </App>
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="chat-bot-creator/:chatBotID"
          element={
            <PrivateRoute>
              <App navBarVisible headerVisible>
                <Provider store={store}>
                  <ChatBotCreator />
                </Provider>
              </App>
            </PrivateRoute>
          }
        /> */}
        <Route
          path="*"
          element={
            <App headerVisible>
              <NotFoundPage />
            </App>
          }
        />
      </Routes>
    </Provider>
  </BrowserRouter>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
//
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
