import {
  TextInput,
  Button,
  Group,
  LoadingOverlay,
  Text,
  PasswordInput,
  Title,
  Stack,
  Anchor
} from '@mantine/core';
import { useForm } from '@mantine/form';
import instance from '../../axiosApi';
import { useState } from 'react';

function RegisterForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isResendConfirmation, setIsResendConfirmation] = useState(false);
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) && !/\s/.test(value) ? null : 'Invalid email'),
      password: (value) => {
        if (value.length < 6) {
          return 'Password must be at least 6 characters';
        }
        if (!/[a-z]/.test(value) || !/[A-Z]/.test(value) || !/\d/.test(value)) {
          return 'Password must contain at least one lowercase letter, one uppercase letter, and one number';
        }
        return null;
      },
      confirmPassword: (value, values) =>
        value !== values.password ? 'Passwords did not match' : null
    }
  });

  const handleRegister = () => {
    setIsLoading(true);
    const emailLower = form.values.email.toLowerCase();
    instance
      .post('/api/auth/register', {
        email: emailLower,
        password: form.values.password
      })
      .then((response) => {
        console.log(response);
        setMessage('Registration successful');
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setMessage(error.response.data.message);
        setIsLoading(false);
      });
  };
  const handleResendConfirmation = () => {
    setIsLoading(true);
    const emailLower = form.values.email.toLowerCase();
    instance
      .post('/api/auth/resend-verification-email', {
        email: emailLower
      })
      .then((response) => {
        console.log(response);
        setMessage('Confirmation email resent');
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setMessage(error.response.data.message);
        setIsLoading(false);
      });
  };

  if (message == 'Registration successful')
    return (
      // Code to display a sucessful registration message and tell th euser they should verify their email

      <Stack>
        <Title color="green" order={5}>
          Registration successful
        </Title>
        <Text color="dimmed" size="sm">
          Confirmation email sent. Remember to check your spam folder.
        </Text>
      </Stack>
    );
  return (
    <form onSubmit={form.onSubmit(handleRegister)}>
      <div>
        <LoadingOverlay visible={isLoading} />
        <TextInput
          label={
            <Text mt={10} mb={10} size="xs">
              Email Address
            </Text>
          }
          placeholder="your@email.com"
          {...form.getInputProps('email')}
        />
        {!isResendConfirmation ? (
          <>
            <PasswordInput
              label={
                <Text mt={10} mb={10} size="xs">
                  Password
                </Text>
              }
              placeholder="Password"
              {...form.getInputProps('password')}
            />
            <PasswordInput
              label={
                <Text mt={10} mb={10} size="xs">
                  Confirm Password
                </Text>
              }
              placeholder="Confirm password"
              {...form.getInputProps('confirmPassword')}
            />{' '}
          </>
        ) : (
          <></>
        )}

        <Group position="right" mt="md">
          {isResendConfirmation ? (
            <Button variant="gradient" fullWidth onClick={handleResendConfirmation}>
              Resend Confirmation
            </Button>
          ) : (
            <Button variant="gradient" fullWidth type="submit">
              Register
            </Button>
          )}
        </Group>
        <Text
          size="sm"
          color={message === 'Registration successful. Confirmation email sent.' ? 'green' : 'red'}>
          {message}
        </Text>
        {/* <Anchor href="/login">Already have an account? Login here.</Anchor>
        <Anchor href="/forgot-password">Forgot password?</Anchor> */}
        {!isResendConfirmation ? (
          <Group position="center">
            <Text size="xs" color="dimmed">
              Already have an account?
            </Text>
            <Anchor
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                display: 'block',
                textDecoration: 'none',
                fontSize: '12px',
                fontWeight: 400
              }}
              onClick={() => {
                //Redirect to register page:
                window.location.href = '/login';
              }}>
              Login
            </Anchor>
            <Anchor
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                display: 'block',
                textDecoration: 'none',
                fontSize: '12px',
                fontWeight: 400
              }}
              onClick={() => setIsResendConfirmation(true)}>
              Resend Confirmation?
            </Anchor>
          </Group>
        ) : (
          <Group position="center">
            <Text size="sm" color="dimmed">
              Already have an account?
            </Text>
            <Anchor
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                display: 'block',
                textDecoration: 'none',
                fontSize: '12px',
                fontWeight: 400
              }}
              onClick={() => {
                //Redirect to register page:
                window.location.href = '/login';
              }}>
              Login
            </Anchor>
            <Anchor
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                display: 'block',
                textDecoration: 'none',
                fontSize: '12px',
                fontWeight: 400
              }}
              onClick={() => setIsResendConfirmation(false)}>
              Register
            </Anchor>
          </Group>
        )}
      </div>
    </form>
  );
}

export default RegisterForm;
