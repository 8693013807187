import { MantineThemeOverride } from '@mantine/core';

const themeDark: MantineThemeOverride = {
  colorScheme: 'dark',
  colors: {
    // override dark colors to change them for all components
    dark: [
      '#d5d7e0',
      '#acaebf',
      '#8c8fa3',
      '#666980',
      '#343A40', // disabled button colour
      'grey', // Line colours
      '#171515', // Menu colour
      '#171515', // Background
      '#0c0d21',
      '#01010a'
    ]
    // 'ocean-blue': [
    //   '#7AD1DD',
    //   '#5FCCDB',
    //   '#44CADC',
    //   '#2AC9DE',
    //   '#1AC2D9',
    //   '#11B7CD',
    //   '#09ADC3',
    //   '#0E99AC',
    //   '#128797',
    //   '#147885'
    // ]
  },
  primaryColor: 'orange',
  defaultGradient: { from: 'pink', to: 'yellow' },
  primaryShade: 9
};

const themeLight: MantineThemeOverride = {
  colorScheme: 'light'
  // colors: {
  //   gold: ['#ECD662'],
  //   green: ['#5D8233'],
  //   blue: ['#284E78'],
  //   four: ['#3E215D'],
  // },
};

export { themeDark, themeLight };
