import { Menu, createStyles, Burger } from '@mantine/core';
import { IconLogout, IconSettings } from '@tabler/icons';
import { useDisclosure } from '@mantine/hooks';
import { resetUserSession } from './AuthComponents/auth';
import { setIsAuthenticated } from '../mainStoreSlices/authSlice';
import { useDispatch } from 'react-redux';

const useStyles = createStyles((theme) => ({
  item: {
    '&[data-hovered]': {
      backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      color: theme.white
    }
  }
}));

function BurgerMenu() {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const label = opened ? 'Close navigation' : 'Open navigation';
  return (
    // Make menu fit screen
    <Menu onClose={toggle} classNames={classes} shadow="md" width={200}>
      <Menu.Target>
        <Burger opened={opened} onClick={toggle} aria-label={label} />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>docmaster.ai</Menu.Label>
        {/* <Menu.Item
          onClick={() => {
            window.location.href = '/account';
          }}
          icon={<IconSettings size={14} />}>
          Account
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            window.location.href = '/my-chat-bots';
          }}
          icon={<IconSettings size={14} />}>
          My Chat Bots
        </Menu.Item> */}
        <Menu.Divider />
        <Menu.Item
          onClick={() => {
            resetUserSession();
            dispatch(setIsAuthenticated(false));
          }}
          icon={<IconLogout size={14} />}>
          Log Out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default BurgerMenu;
