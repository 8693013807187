import { createStyles, Container, Title, Text, Button, Group, Stack } from '@mantine/core';
import Break from './Break';
import { useMediaQuery } from '@mantine/hooks';
import { useSelector } from 'react-redux';

const useStyles = createStyles((theme) => ({
  dots: {
    position: 'absolute',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    },
    zIndex: -1
  },
  title: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: 40,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
  },

  description: {
    textAlign: 'center',

    [theme.fn.smallerThan('xs')]: {
      textAlign: 'left',
      fontSize: theme.fontSizes.md
    }
  },

  shadowCard: {
    border: '1px solid #eaeaea',
    boxShadow: '0 2px 8px #f0f1f2'
  },

  control: {},
  root: {
    paddingTop: 5,
    paddingBottom: 5
  }
}));

function LandingPage() {
  const authState = useSelector((state: any) => state.auth);
  console.log('isAuthenticated: ', authState.isAuthenticated);
  console.log('isAuthenticating: ', authState.isAuthenticating);
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Container className={classes.root}>
      <Stack mt={150}>
        <div>
          <Title variant="gradient" className={classes.title}>
            docmaster.ai
          </Title>
          <Text style={{ textAlign: 'center' }} color="dimmed" size="sm">
            Create fully customisable chatbots for your business documentation.
          </Text>
          <Break height={1} />

          <Group position="center">
            {!authState.isAuthenticated ? (
              <>
                <Button
                  disabled
                  component="a"
                  href="/my-chat-bots"
                  size="md"
                  mt="xl"
                  className={classes.control}>
                  Coming soon...
                </Button>
                {/* <Button
                  component="a"
                  href="/login"
                  variant="outline"
                  size="md"
                  mt="xl"
                  className={classes.control}>
                  Login
                </Button>
                <Button
                  component="a"
                  href="/register"
                  variant="gradient"
                  size="md"
                  mt="xl"
                  className={classes.control}>
                  Register
                </Button>{' '} */}
              </>
            ) : (
              <Button
                disabled
                component="a"
                href="/my-chat-bots"
                size="md"
                mt="xl"
                className={classes.control}>
                Coming soon...
              </Button>
            )}
          </Group>
          <Break />
        </div>
        {/* Add a grid of 4 cards to describe a feature in each with an icon and one line of text */}
        {/* 
        <Grid>
          <Grid.Col span={3}>
            <Card className={classes.shadowCard}>
              <Title order={4}>Build a chatbot</Title>
            </Card>
          </Grid.Col>
          <Grid.Col span={3}>
            <Card className={classes.shadowCard}>
              <Title order={4}>Use your own data</Title>
            </Card>
          </Grid.Col>
          <Grid.Col span={3}>
            <Card className={classes.shadowCard}>
              <Title order={4}>Customisable</Title>
            </Card>
          </Grid.Col>
          <Grid.Col span={3}>
            <Card className={classes.shadowCard}>
              <Title order={4}>Easy to use</Title>
            </Card>
          </Grid.Col>
        </Grid> */}
        {/* <video width="100%" height="100%" controls>
          <source src={exampleVideo} type="video/mp4" />
        </video> */}
      </Stack>
    </Container>
  );
}

export default LandingPage;
