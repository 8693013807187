import { createStyles } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
export interface DotsProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number;
  height?: number;
  width?: number;
  dotSize?: number;
}

const useStyles = createStyles((theme) => ({
  dots: {
    position: 'absolute',
    opacity: 0.5,
    zIndex: -1,
    color: 'orange'
  }
}));
export function Dots({ dotSize = 1, ...others }: DotsProps) {
  const { height, width } = useViewportSize();
  const dotSpacing = dotSize * 50;
  const numDots = Math.floor(width / dotSpacing) * 3;
  const dotOffset = (width % dotSpacing) / 2;
  const { classes } = useStyles();

  return (
    <div className={classes.dots}>
      <svg
        aria-hidden
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        {...others}>
        {Array(numDots)
          .fill(null)
          .map((_, row) =>
            Array(numDots)
              .fill(null)
              .map((_, col) => (
                <rect
                  key={`${row}-${col}`}
                  width={dotSize}
                  height={dotSize}
                  x={dotOffset + col * dotSpacing}
                  y={dotOffset + row * dotSpacing}
                  rx={dotSize / 2}
                />
              ))
          )}
      </svg>
    </div>
  );
}
