import { Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconX, IconFileCheck, IconCloudUpload, IconFileText, IconFile } from '@tabler/icons';
import { useMediaQuery } from '@mantine/hooks';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { useState } from 'react';
import instance from '../../axiosApi';

interface XhtmlPreviewProps {
  uploadedFiles: FileWithPath[];
  setUploadedFiles: (uploadedFiles: FileWithPath[]) => void;
  documentText: string;
  setDocumentText: (documentText: string) => void;
  isLargeDoc: boolean;
  setIsLargeDoc: (isLargeDoc: boolean) => void;
  isTooLarge: boolean;
  setIsTooLarge: (isTooLarge: boolean) => void;
  chatCredits: number;
  setChatCredits: (chatCredits: number) => void;
  searchCredits: number;
  setSearchCredits: (searchCredits: number) => void;
  textLength: number;
  setTextLength: (textLength: number) => void;
  sentenceCount: number;
  setSentenceCount: (sentenceCount: number) => void;
  wordCount: number;
  setWordCount: (wordCount: number) => void;
  searchResults: any;
  setSearchResults: (searchResults: any) => void;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
}

function UploadDropzone(props: XhtmlPreviewProps) {
  const {
    uploadedFiles,
    setUploadedFiles,
    documentText,
    setDocumentText,
    isLargeDoc,
    setIsLargeDoc,
    isTooLarge,
    setIsTooLarge,
    chatCredits,
    setChatCredits,
    searchCredits,
    setSearchCredits,
    textLength,
    setTextLength,
    sentenceCount,
    setSentenceCount,
    wordCount,
    setWordCount,
    searchResults,
    setSearchResults,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText
  } = props;
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [error, setError] = useState('');

  const isMobile = useMediaQuery('(max-width: 600px)');
  const dropzoneHeight = isMobile ? 100 : 100;
  function handleReject() {
    setIsRejected(true);
  }
  async function handleOnDrop(files: FileWithPath[]) {
    setIsRejected(false);
    setUploadedFiles(files);
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('file', files[0]);
    // const token = await getToken();
    // const user = await getUser();
    // if (!token) {
    //   setError('Please login or register.');
    //   setIsLoading(false);
    //   return;
    // }
    // if (!user) {
    //   setError('Please login or register.');
    //   setIsLoading(false);
    //   return;
    // }
    // formData.append('email', user.email);
    // formData.append('userID', user.userID);
    // formData.append('credits', user.credits);
    // formData.append('isEmailVerified', user.isEmailVerified);
    try {
      const response = await instance.post('/api/uploadExtractText', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setDocumentText(response.data.text);
      setChatCredits(response.data.creditsNeeded);
      setSearchCredits(response.data.searchCreditsNeeded);
      setTextLength(response.data.textLength);
      setIsLargeDoc(response.data.isLargeDoc);
      setIsTooLarge(response.data.isTooLarge);
      setWordCount(response.data.wordCount);
      setSentenceCount(response.data.sentenceCount);
    } catch (error: any) {
      console.error(error);
      // Error handling to catch and display common error codes:
      // 400: Bad Request
      // 401: Unauthorized
      // 403: Forbidden
      // 404: Not Found
      // 500: Internal Server Error
      // 502: Bad Gateway
      // 503: Service Unavailable
      // 504: Gateway Timeout
      if (error.response) {
        switch (error.response.status) {
          case 400:
            setError(error.response.data.message);
            break;
          case 401:
            setError('Unauthorized - Please login or register.');
            break;
          case 403:
            setError("You don't have access. Try logging in.");
            break;
          case 404:
            setError('Not Found');
            break;
          case 413:
            setError('Too many tokens. Try a smaller file with fewer than 8000 chars / 1500 words');
            break;
          case 500:
            setError(
              'Your file could not be processed. Try a smaller file. Larger files will be supported soon.'
            );
            break;
          case 502:
            setError('Bad Gateway');
            break;
          case 503:
            setError('Service Unavailable');
            break;
          case 504:
            setError('Gateway Timeout');
            break;
          default:
            setError('An error occurred while analysing the file.');
        }
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <Text size="xs" color="red">
        {error}
      </Text>
      <Dropzone
        data-id={'Upload-Dropzone'}
        loading={isLoading}
        onDrop={handleOnDrop}
        onReject={() => handleReject()}
        maxSize={1024 * 1024 * 10} // Max size of 10MB
        maxFiles={1}
        accept={{ 'application/pdf': ['.pdf'] }}>
        <Group
          position="center"
          spacing="md"
          style={{ minHeight: dropzoneHeight, pointerEvents: 'none' }}>
          <Stack>
            {uploadedFiles.length > 0 ? (
              <Group align="center">
                <IconFileCheck size={50} stroke={1.5} />
                <Text size="xs" color="dimmed">
                  File Attached: {uploadedFiles[0].name}
                </Text>
              </Group>
            ) : (
              <div>
                <Group position="right" spacing="md">
                  <Dropzone.Accept>
                    <IconCloudUpload
                      size={50}
                      stroke={1.5}
                      color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX
                      size={50}
                      stroke={1.5}
                      color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                    />
                  </Dropzone.Reject>

                  <Stack spacing="xs">
                    <Dropzone.Idle>
                      <Group position="center">
                        <IconCloudUpload size={50} stroke={1.5} />
                      </Group>
                    </Dropzone.Idle>
                    <Group position="center">
                      <Text color="dimmed" size="sm">
                        Select or drop a PDF here.
                      </Text>
                    </Group>
                    <Group position="center">
                      <Text color="dimmed" size="xs">
                        Max pages ~ 100 | 120000 characters.
                      </Text>
                    </Group>
                    <Group position="center">
                      <Text color="dimmed" size="xs">
                        Max file size 10MB.
                      </Text>
                    </Group>
                  </Stack>
                </Group>
              </div>
            )}
            {isRejected ? (
              <Group align="left">
                <IconX
                  size={20}
                  stroke={1.5}
                  color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                />
                <Text size="sm" color="dimmed" inline mt={7}>
                  File(s) Rejected - Incorrect type, size or count.
                </Text>
              </Group>
            ) : (
              <></>
            )}
          </Stack>
        </Group>
      </Dropzone>
    </>
  );
}

export default UploadDropzone;
