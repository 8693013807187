import {
  TextInput,
  Text,
  Button,
  Group,
  LoadingOverlay,
  PasswordInput,
  Anchor
} from '@mantine/core';
import { useForm } from '@mantine/form';
import instance from '../../axiosApi';
import { setUserSession } from './auth';
import { useState } from 'react';
import { setIsAuthenticated, setIsAuthenticating } from '../../mainStoreSlices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LogoutForm from './LogoutForm';

function LoginForm() {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    }
  });

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await instance.post('/api/auth/login', {
        email: form.values.email.toLowerCase(),
        password: form.values.password
      });

      console.log(response);
      setUserSession(response.data.token, response.data.user);
      dispatch(setIsAuthenticated(true));
      // Redirect to home page:
      window.location.href = '/';
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMessage('Login failed. Please check your email and password');
    }
  };

  // if (auth.isAuthenticated) {
  //   return <LogoutForm />;
  // }

  return (
    <form onSubmit={form.onSubmit(handleLogin)}>
      <div>
        <LoadingOverlay visible={isLoading} />
        <TextInput
          label={
            <Text mt={10} mb={10} size="xs">
              Email Address
            </Text>
          }
          placeholder="your@email.com"
          {...form.getInputProps('email')}
        />
        <PasswordInput
          label={
            <Text mt={10} mb={10} size="xs">
              Password
            </Text>
          }
          placeholder="Password"
          {...form.getInputProps('password')}
        />
        <Group position="right" mt="md">
          <Button variant="gradient" fullWidth type="submit">
            Login
          </Button>
        </Group>
        {/* Conditional formatting for whether the message is success or not: */}
        <Text size="sm" color={message.includes('success') ? 'green' : 'red'}>
          {message}
        </Text>
        <Group position="center">
          <Text size="xs">Don't have an account?</Text>
          <Anchor
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              display: 'block',
              textDecoration: 'none',
              fontSize: '12px',
              fontWeight: 400
            }}
            onClick={() => {
              //Redirect to register page:
              window.location.href = '/register';
            }}>
            Register
          </Anchor>
        </Group>
      </div>
    </form>
  );
}

export default LoginForm;
