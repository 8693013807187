import { configureStore } from '@reduxjs/toolkit';
import auth from './mainStoreSlices/authSlice';

const mainStore = configureStore({
  reducer: {
    auth: auth
  }
});

export default mainStore;
